import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./mystyles.scss"
import {useEffect, useState} from "react";
import  {isLoggedIn} from "../utils/auth.service";
import {graphql, navigate} from "gatsby";
import LoadingSpinner from "../components/loading-spinner";
import authHeader from "../utils/auth-header";
import {convertPrice} from "../utils/helper.service";
import InvoiceList from "../components/invoices/list";
import {useTranslation} from "gatsby-plugin-react-i18next";


const Invoices = () => {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [monthSum, setMonthSum] = useState([]);
    const [monthSumExpected, setMonthSumExpected] = useState([]);
    const [invoices, setInvoices] = useState([]);

    useEffect(() => {

        if(!isLoggedIn()){
            return navigate('/');
        }

        loadInvoices();


    }, [])


    const loadInvoices= () => {

        setLoading(true);

        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };


        fetch(process.env.WASHCAR_API_URL + "/business/invoices", requestOptions)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                setLoading(false);
                setMonthSum(resultData.data.current_month_sum);
                setMonthSumExpected(resultData.data.current_month_sum_expected);
                setInvoices(resultData.data.invoices);


            }) //
    }


    return (
        <Layout>

            <Seo title={t('invoices')}/>

            <h1 className="title">{t('invoices')}</h1>

            <div className={'columns'}>

                <div className={'column'}>
                    <div className="box notification is-info">
                        <div className="heading">{t('current_mont_invoice_sum')}</div>
                        <div className="title">{convertPrice(monthSum)}</div>
                    </div>
                </div>

                <div className={'column'}>
                    <div className="box notification is-info">
                        <div className="heading">{t('current_mont_invoice_sum_for_booking')}</div>
                        <div className="title">{convertPrice(monthSumExpected)}</div>
                    </div>
                </div>

            </div>


            {loading ? <div className="pos-center"><LoadingSpinner /></div> : <div>

                {invoices.length === 0 ?<p className={'box notification is-warning'}>{t('no_invoices_text')}</p> :
                    <InvoiceList invoices={invoices}/> }


            </div>}

        </Layout>
    )
}

export default Invoices

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;