import {getActiveLocale} from "../utils/auth.service";

export default function authHeader() {

  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.accessToken) {
    return {
      "Content-Type": "application/json",
      Authorization: 'Bearer' + user.accessToken,
      Accept: 'application/json',
      'x-localization': getActiveLocale(),
    };

  } else {
    return {
      "Content-Type": "application/json",
      Accept: 'application/json',
      'x-localization': getActiveLocale(),
    };
  }
}
