import React from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";
import LoadingSpinner from "../loading-spinner";

const InvoiceList = (props) => {

    const {t} = useTranslation();

    return (

        <div className="card">

            <div className="card-content">

                <div className="content">
                    <table className="table is-fullwidth">

                        <tbody>

                        {props.invoices.map(invoice => (
                            <tr key={invoice.id}>
                                <td><b>{invoice.month_name_translated}</b>  <small>{invoice.year}</small> ({invoice.invoice_number})</td>

                                {invoice.paid_at === null ? <td> <div className={'tag is-danger'}>{t('need_pay_until')}: <b> {invoice.payment_deadline}</b></div></td> : <td><div className={'tag is-success'}> {t('paid_at')}: <b> {invoice.paid_at}</b></div></td>}

                                <td>
                                    <a rel={'noopener'} target={'_blank'} href={invoice.pdf_link}
                                       className={'button is-primary'}>{t('view')}</a>
                                </td>

                            </tr>
                        ))}


                        </tbody>
                    </table>
                </div>
            </div>
        </div>


    );
};

export default InvoiceList;
