import React from 'react';

const LoadingSpinner = () => {

    return (

        <div className="loader"></div>

    );
};

export default LoadingSpinner;
